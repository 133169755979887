import { CircularProgressbar as CircularProgress } from "react-circular-progressbar"
import {
  LaunchStatusType,
  PlatformNext,
  UndefinedModelDefinition,
  DefaultStreamerOptions,
  StreamerStatus,
  Resolution,
  streamResolutionConfiguration,
} from "@pureweb/platform-sdk"

import {
  useStreamer,
  useLaunchRequest,
  IdleTimeout,
  VideoStream,
  System,
} from "@pureweb/platform-sdk-react"
import { Tooltip as ReactTooltip } from 'react-tooltip'
import qs from "query-string"
import React, { useEffect, useState, useRef } from "react"
import { FullScreen, useFullScreenHandle } from "react-full-screen"
import { Button, Icon } from "semantic-ui-react"
import useAsyncEffect from "use-async-effect"
import "./pureweb.css"
import clientConfig from "./client.json"
import { LaunchView } from "./Launch"
import logger from "./Log"
import { DownloadBar, ImgBBContainer, MessagePopup, Upload3DContainer } from "../emulatornewpureweb"
import { useContext } from "react"
import { purewebContext } from "../../../../context/PurewebContext"
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import { Typography } from '@mui/material'
import { useLocation } from "react-router-dom"
import UploadWidget from '../../../UploadWidget/UploadWidget'

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  zIndex: 1,
  borderRadius: 2
};

const CircularProgressBar = () => {
  const [value, setValue] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setValue((prevValue) => {
        if (prevValue === 100) {
          clearInterval(interval)
          return prevValue
        }
        return prevValue + 1
      })
    }, 600)

    return () => {
      clearInterval(interval)
    }
  }, [])

  return <CircularProgress value={value} />
}

const client = clientConfig

class ClientJson {}

class ClientOptions {
  // Overridable streamer options
  ForceRelay = false

  isValid() {
    if (!this.ProjectId) {
      return false
    }
    if (!this.ModelId) {
      return false
    }
    return true
  }
}

const LoadingView = (props) => {
  if (
    props.StreamerStatus === StreamerStatus.Connected ||
    props.StreamerStatus === StreamerStatus.Completed
  ) {
    return <div />
  }

  let content;

  if (props.StreamerStatus === StreamerStatus.NotSupported) {
    content = (
      <div>
        <h3>
          Your browser does not support the necessary WebRTC capabilities.
        </h3>
      </div>
    )
  }
  if (
    props.LaunchRequestStatus.status === LaunchStatusType.Unavailable ||
    props.LaunchRequestStatus.status === LaunchStatusType.Error ||
    props.StreamerStatus === StreamerStatus.Failed
  ) {
    content = (
      <div>
        <h3>The experience is presently unavailable.</h3>
        <h3>Please refresh to request a new session.</h3>
      </div>
    )
    // props.setRefreshRequiredModalVisible?.(true)
  } else {
    content = (
      <div>
        {/* <img alt="pureweb logo" src="/pureweb.animated.svg" /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: 60,
              height: 60
            }}
          >
            <CircularProgressBar />
            <div style={{ marginTop: 15 }}>
              <h3 className="responsive-text-color">Loading...</h3>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div
      style={{
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
        width: "100%",
        height: "100%"
      }}
    >
      <div style={{ textAlign: "center" }}>{content}</div>
    </div>
  )
}

const EmbeddedView = (props) => {
  const {
    upload3DContainerVisible,
    imgbbContainerVisible,
    downloadVisible,
    setDownloadVisible,
    preparingDownload,
    setPreparingDownload,
    renderStarted,
    imagefileone,
    fullScreenTrigger,
    refreshRequiredModalVisible,
    setRefreshRequiredModalVisible,
    lowConnectionMessageVisible,
    setLowConnectionMessageVisible,
    shownNetworkMessage,
    setShownNetworkMessage,
    userLoggedIn,
    currentEmitter: currentPlayer,
    setImgbbContainerVisible,
    streamQuality
  } = useContext(purewebContext)
  const [confirmFullScreenModalVisible, setConfirmFullScreenModalVisible] = useState(false);
  const [messageTriggerTimer, setMessageTriggerTimer] = useState(null)
  const handleFullScreenModalOpen = () => setConfirmFullScreenModalVisible(true);
  const handleFullScreenModalClose = () => setConfirmFullScreenModalVisible(false);
  const videoRef = useRef(null)
  const handle = useFullScreenHandle()
  const [cloudfrontUrl, setCloudfrontUrl] = useState("");
  // Fullscreen API presently supported on iPad, but not iPhone or iPod
  const isIPhone =
    System.Browser().os === "iOS" &&
    !window.navigator.userAgent.includes("iPad")

  useEffect(() => {
    if(fullScreenTrigger === "noinit") return;
    if(handle.active) {
      console.log("exiting")
      handle.exit()
    } else {
      console.log("entering")
      handle.enter()
    }    
  },[handle, fullScreenTrigger])

  useEffect(() => {
    if(cloudfrontUrl) {
      currentPlayer?.EmitUIInteraction(`ImageLinkString,${cloudfrontUrl}`)
      currentPlayer?.EmitUIInteraction(`IMGBB_MODAL_CLOSE`)
    }
  }, [cloudfrontUrl])

  useEffect(() => {
    if (!imgbbContainerVisible) {
      currentPlayer?.EmitUIInteraction(`IMGBB_MODAL_CLOSE`)
    } else if(imgbbContainerVisible && handle.active) {
      handle.exit()
    }
  }, [imgbbContainerVisible])

  useEffect(() => {
    if (!userLoggedIn) return
    if (window.navigator?.connection) {
      const timerId = setInterval(() => {
        const networkSpeed = window.navigator.connection.downlink
        if (networkSpeed < 5) {
          setLowConnectionMessageVisible(true)
          setShownNetworkMessage(true)
        } else {
          setLowConnectionMessageVisible(false)
        }
      }, 90000)
      setMessageTriggerTimer(timerId)
    } else {
      console.log("navigator.connection API is not supported in this browser.")
    }
  }, [userLoggedIn])

  useEffect(() => {
    if (messageTriggerTimer && shownNetworkMessage) {
      clearInterval(messageTriggerTimer)
    }
  }, [messageTriggerTimer, shownNetworkMessage])

  async function downloadImage(imageSrc, nameOfDownload = "Image.png") {
    const response = await fetch(imageSrc)

    const blobImage = await response.blob()

    const href = URL.createObjectURL(blobImage)

    const anchorElement = document.createElement("a")
    anchorElement.href = href
    anchorElement.download = nameOfDownload

    document.body.appendChild(anchorElement)
    anchorElement.click()

    document.body.removeChild(anchorElement)
    window.URL.revokeObjectURL(href)
  }

  const saveimage = async (name) => {
    if (name === "first" && imagefileone) {
      downloadImage(imagefileone, "Image.png")
        .then(() => {
          setPreparingDownload(false);
          setDownloadVisible(false);
          console.log("The image has been downloaded")
        })
        .catch((err) => {
          console.log("Error downloading image: ", err)
        })
    }
  }

  const handleFullScreen = () => {
    if (handle.active) {
      handle.exit()
    } else {
      var issafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
      if (issafari) {
        handleFullScreenModalOpen()
      } else {
        handle.enter()
      }
    }
  }

  return (
    // <div style={{ height: "100%", aspectRatio: 1920 / 1080 }}>
    <div className="responsive-pureweb-container">
      {confirmFullScreenModalVisible && (
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Enter full screen?
          </Typography>
          <div className="full-screen-confirmation-modal--button-container">
            <button
              className="full-screen-confirmation-modal--confirm"
              onClick={() => {
                handle.enter()
                handleFullScreenModalClose()
              }}
            >
              Yes
            </button>
            <button
              className="full-screen-confirmation-modal--cancel"
              onClick={() => {
                handleFullScreenModalClose()
              }}
            >
              Cancel
            </button>
          </div>
        </Box>
      )}
      <FullScreen className="full-screen-handler--container" handle={handle}>
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
        >
          <IdleTimeout
            Status={props.StreamerStatus}
            WarningThreshold={1200}
            ExitThreshold={120}
            WarningCallback={handle.exit}
            // TODO: How to 'close' a contribution?
            ExitCallback={() => window.location.reload()}
          />

          <LoadingView
            LaunchRequestStatus={props.LaunchRequestStatus}
            StreamerStatus={props.StreamerStatus}
            setRefreshRequiredModalVisible={setRefreshRequiredModalVisible}
          />
          <VideoStream
            VideoRef={videoRef}
            Emitter={props.InputEmitter}
            Stream={props.VideoStream}
            UseNativeTouchEvents={props.UseNativeTouchEvents}
            UsePointerLock={props.UsePointerLock}
            PointerLockRelease={props.PointerLockRelease}
            Resolution={streamResolutionConfiguration(streamQuality)}
          />

          <Button
            id="full_screen_button"
            onClick={handleFullScreen}
            style={{
              position: "absolute",
              top: 10,
              right: 0,
              opacity: 0,
              width: 0,
              height: 0,
            }}
            className={
              isIPhone ||
              handle.active ||
              props.StreamerStatus !== StreamerStatus.Connected
                ? "hidden"
                : ""
            }
          >
            {/* <Icon name="expand" /> */}
            {/* Enter Full Screen */}
          </Button>

          {/* {imgbbContainerVisible && (
            <div className="image-upload-modal-main-wrapper">
              <ImgBBContainer></ImgBBContainer>
            </div>
          )} */}

          <UploadWidget
            isOpen={imgbbContainerVisible}
            onSuccess={(result) => {
              if (
                result.event === "success" &&
                Boolean(result?.info?.secure_url)
              ) {
                setCloudfrontUrl(result.info.secure_url)
                setImgbbContainerVisible(false)
              }

              if (result.info === "hidden") {
                setImgbbContainerVisible(false)
              }
            }}
            onError={(err) => {
              console.log("This is error")
              console.log(err)
              setImgbbContainerVisible(false)
              alert(err.message || "Failed to upload")
            }}
          ></UploadWidget>

          {upload3DContainerVisible && (
            <div className="image-upload-modal-main-wrapper">
              <Upload3DContainer></Upload3DContainer>
            </div>
          )}

          {handle.active && (
            <ReactTooltip
              anchorSelect=".click-to-copy"
              id="my-tooltip"
              style={{ zIndex: 1000 }}
            />
          )}

          {(renderStarted || (downloadVisible && imagefileone)) && (
            <DownloadBar
              imageUrl={imagefileone}
              onDownloadClick={() => {
                saveimage("first")
                setPreparingDownload(true)
              }}
              onCancel={() => setDownloadVisible(false)}
              preparingDownload={preparingDownload}
              renderStarted={renderStarted}
              isFullScreen={handle.active}
            ></DownloadBar>
          )}
          {lowConnectionMessageVisible && (
            <MessagePopup
              onOkClicked={() => {
                setLowConnectionMessageVisible(false)
              }}
            />
          )}
          {refreshRequiredModalVisible && (
            <MessagePopup
              onOkClicked={() => {
                setRefreshRequiredModalVisible(false)
              }}
              type="refresh"
            />
          )}
        </div>
      </FullScreen>
    </div>
  )
}

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio()
audio.autoplay = true
audio.volume = 0.5

// Parse query parameters
const query = qs.parse(window.location.search)
const clientOptions = new ClientOptions()
clientOptions.LaunchType = query["launchType"] ?? client.launchType
if (query["collaboration"] && query["collaboration"] === "true") {
  clientOptions.LaunchType = "local"
}

clientOptions.Endpoint = query["endpoint"] ?? client.endpoint
// clientOptions.ProjectId = query["projectId"] ?? client.projectId
clientOptions.ModelId = query["modelId"] ?? client.modelId
clientOptions.Version = query["version"] ?? client.version
clientOptions.EnvironmentId = query["environmentId"] ?? client.environmentId
clientOptions.Resolution = query["resolution"] ?? client.resolution
clientOptions.Resolution = clientOptions.Resolution ?? Resolution.fhd
// use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
clientOptions.UsePointerLock =
  (query["usePointerLock"] === undefined
    ? client.usePointerLock
    : query["usePointerLock"] === "true") ?? true
// release the pointer lock on mouse up if true
clientOptions.PointerLockRelease =
  (query["pointerLockRelease"] === undefined
    ? client.pointerLockRelease
    : query["pointerLockRelease"] === "true") ?? true

clientOptions.ForceRelay = query["forceRelay"] !== undefined ?? false
clientOptions.UseNativeTouchEvents =
  (query["useNativeTouchEvents"] === undefined
    ? client.useNativeTouchEvents
    : query["useNativeTouchEvents"] === "true") ?? false
// Initialize platform reference
const platform = new PlatformNext()
platform.initialize({
  endpoint: clientOptions.Endpoint || "https://api.pureweb.io",
})

const App = ({
  onConnectionSuccess,
  onEmitterChange,
  onPurewebMessageReceived,
  loginVisible,
  locationChoice
}) => {
  const [modelDefinitionUnavailable, setModelDefinitionUnavailable] =
    useState(false)
  const [modelDefinition, setModelDefinition] = useState(
    new UndefinedModelDefinition()
  )
  const [availableModels, setAvailableModels] = useState()
  const [launchRequestError, setLaunchRequestError] = useState()
  const streamerOptions = DefaultStreamerOptions
  const dashSelector = useSelector((state) => state.adminReducer);
  const location = useLocation();
  const mode = location.search ? new URLSearchParams(location.search).get("mode") : ''

  useAsyncEffect(async () => {
    if (process.env.REACT_APP_ENVIRONMENT !== "development" || mode === "main") {
      if(locationChoice === "USA") {
        if (dashSelector?.renderSection?.purewebProjectId) {
          try {
            await platform.useAnonymousCredentials(
              dashSelector?.renderSection?.purewebProjectId,
              clientOptions.EnvironmentId
            );
            await platform.connect();
            console.log("PLATFORM CONNECTION SUCCESSFUL");
            logger.info("Agent Connected: " + platform.agent.id);
            streamerOptions.iceServers =
              platform.agent.serviceCredentials.iceServers;
            streamerOptions.forceRelay = clientOptions.ForceRelay;
            const models = await platform.getModels();
            setAvailableModels(models);
            logger.debug("Available models", models);
          } catch (err) {
            console.log("platform connection failed");
            logger.error(err);
          }
        }
      } else {
        if (dashSelector?.renderSection?.purewebProjectIdGlobal) {
          try {
            await platform.useAnonymousCredentials(
              dashSelector?.renderSection?.purewebProjectIdGlobal,
              clientOptions.EnvironmentId
            );
            await platform.connect();
            console.log("PLATFORM CONNECTION SUCCESSFUL");
            logger.info("Agent Connected: " + platform.agent.id);
            streamerOptions.iceServers =
              platform.agent.serviceCredentials.iceServers;
            streamerOptions.forceRelay = clientOptions.ForceRelay;
            const models = await platform.getModels();
            setAvailableModels(models);
            logger.debug("Available models", models);
          } catch (err) {
            console.log("platform connection failed");
            logger.error(err);
          }
        }
      }
    } else {
      if (dashSelector?.renderSection?.purewebProjectIdDev) {
        try {
          await platform.useAnonymousCredentials(
            dashSelector?.renderSection?.purewebProjectIdDev,
            clientOptions.EnvironmentId
          );
          await platform.connect();
          console.log("PLATFORM CONNECTION SUCCESSFUL");
          logger.info("Agent Connected: " + platform.agent.id);
          streamerOptions.iceServers =
            platform.agent.serviceCredentials.iceServers;
          streamerOptions.forceRelay = clientOptions.ForceRelay;
          const models = await platform.getModels();
          setAvailableModels(models);
          logger.debug("Available models", models);
        } catch (err) {
          console.log("platform connection failed");
          logger.error(err);
        }
      }
    }
  }, [clientOptions, dashSelector]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT !== "development" || mode === "main") {
      if (availableModels?.length) {
        const selectedModels = availableModels.filter(function (model) {
          if (locationChoice === "USA" ? dashSelector?.renderSection?.purewebModelId === model.id : dashSelector?.renderSection?.purewebModelIdGlobal === model.id) {
            // If there is a version specified and we encounter it
            if (
              clientOptions.Version &&
              clientOptions.Version === model.version
            ) {
              return true;
            }
            // If there is no version specified and we find the primary version
            if (!clientOptions.Version && model.active) {
              return true;
            }
          }
          return false;
        });
        if (selectedModels?.length) {
          setModelDefinition(selectedModels[0]);
        } else {
          setModelDefinitionUnavailable(true);
        }
      }
    } else {
      if (availableModels?.length) {
        const selectedModels = availableModels.filter(function (model) {
          if (dashSelector?.renderSection?.purewebModelIdDev === model.id) {
            // If there is a version specified and we encounter it
            if (
              clientOptions.Version &&
              clientOptions.Version === model.version
            ) {
              return true;
            }
            // If there is no version specified and we find the primary version
            if (!clientOptions.Version && model.active) {
              return true;
            }
          }
          return false;
        });
        if (selectedModels?.length) {
          setModelDefinition(selectedModels[0]);
        } else {
          setModelDefinitionUnavailable(true);
        }
      }
    }
  }, [availableModels]);

  const launchRequestOptions = {
    virtualizationProviderOverride: query["virtualizationProviderOverride"],
  }
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(
    platform,
    modelDefinition,
    launchRequestOptions
  )
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] =
    useStreamer(platform, launchRequest, streamerOptions)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    onEmitterChange(emitter)
  }, [emitter])

  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect()
    }
  }, [streamerStatus])

  if (audioStream) {
    audio.srcObject = audioStream
  }

  const launch = async () => {
    setLoading(true)
    audio.load()

    if (clientOptions.LaunchType !== "local") {
      try {
        await queueLaunchRequest()
      } catch (err) {
        setLaunchRequestError(err)
      }
    }
  }

  // Log status messages
  useEffect(() => {
    logger.info("Status", status, streamerStatus)
    if (status.status === "ready") {
      onConnectionSuccess(true)
    }
  }, [status, streamerStatus])

  // Subscribe to game messages
  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value) => {
        logger.info("Message: " + value)
        console.log(typeof value)
        console.log(value.length)
        const myBuffer = []
        const str = value
        const buffer = new Buffer(str, "utf16le")
        for (var i = 0; i < buffer.length; i++) {
          myBuffer.push(buffer[i])
        }
        console.log(myBuffer)
        onPurewebMessageReceived(value)
      },
      (err) => {
        logger.error(err)
      }
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [messageSubject])

  // Notify user of missing or errors in configuration
  // if (!clientOptions.isValid() && clientOptions.LaunchType !== "local") {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         height: "100%",
  //         overflow: "none",
  //         textAlign: "center",
  //         alignItems: "center",
  //         justifyContent: "center",
  //       }}
  //     >
  //       <p className='responsive-text-color'>
  //         Your client has one or more configuration errors. Please consult the{" "}
  //         <a href="https://www.npmjs.com/package/@pureweb/cra-template-pureweb-client">
  //           {" "}
  //           README{" "}
  //         </a>{" "}
  //         for details on how to configure the client template.
  //       </p>
  //     </div>
  //   )
  // }

  if (modelDefinitionUnavailable && clientOptions.LaunchType !== "local") {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className='responsive-text-color'>The model that you have requested does not exist</span>
      </div>
    )
  }

  if (launchRequestError) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <span className='responsive-text-color'>
          {process.env.NODE_ENV === "development"
            ? `There was an error with the launch request: ${launchRequestError}`
            : "It appears the requested model is currently not online as per your set schedule. Please contact support if it should be available."}
        </span>
      </div>
    )
  }

  // Begin connection
  if (streamerStatus === StreamerStatus.Disconnected) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 className='responsive-text-color'>Disconnected from stream</h2>
      </div>
    )
  }

  if (streamerStatus === StreamerStatus.Failed) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 className='responsive-text-color'>Failure during stream</h2>
        <h2 className='responsive-text-color'>Please refresh to request a new session</h2>
      </div>
    )
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 className='responsive-text-color'>Streamer contribution withdrawn</h2>
      </div>
    )
  }

  if (loading) {
    return (
      <EmbeddedView
        VideoStream={videoStream}
        StreamerStatus={streamerStatus}
        LaunchRequestStatus={status}
        InputEmitter={emitter}
        UseNativeTouchEvents={clientOptions.UseNativeTouchEvents}
        UsePointerLock={clientOptions.UsePointerLock}
        PointerLockRelease={clientOptions.PointerLockRelease}
        Resolution={clientOptions.Resolution}
        onConnectionSuccess={onConnectionSuccess}
      />
    )
  } else if (clientOptions.LaunchType !== "local" && !availableModels) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 className='responsive-text-color'>Initializing...</h2>
      </div>
    )
  } else if (clientOptions.LaunchType !== "local" && !availableModels?.length) {
    return (
      <div
        style={{
          display: "flex",
          height: "100%",
          overflow: "none",
          textAlign: "center",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 className='responsive-text-color'>No models are currently available in this environment.</h2>
      </div>
    )
  } else {
    return <LaunchView Launch={launch} />
  }
}

const PurewebContainer = ({ ...props }) => {
  return System.IsBrowserSupported() ? (
    <App {...props} />
  ) : (
    <div className="ui red segment center aligned basic">
      <h2 className="header">Your browser is currently unsupported</h2>
    </div>
  )
}

export default PurewebContainer
