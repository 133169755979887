import { Button } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import validator from "validator";

const Playfab = () => {
  const list = useSelector((state) => state.adminReducer.mailingList);
  const [admins, setAdmins] = useState([]);
  const [removedAdmin, setRemovedAdmin] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchAdmins = () => {
    axios
      .get("https://api.rendernow.co/getPlayerAdmins")
      .then((response) => {
        setAdmins(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const updateAdmin = () => {
    const newAdmins = admins.filter((admin) => !admin._id);
    setLoading(true);
    axios
      .post("https://api.rendernow.co/updatePlayerAdmins", {
        newAdmins,
        removedAdmin: removedAdmin,
      })
      .then((response) => {
        alert("Admin list updated successfully");
        fetchAdmins();
      })
      .catch((err) => {
        alert("Failed to update admin list!" + err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const removeAdmin = (index) => {
    const removeAdmin = admins[index];
    if (removeAdmin._id) {
      setRemovedAdmin([...removedAdmin, removeAdmin._id]);
    }
    setAdmins(admins.filter((_, i) => i !== index));
  };

  function handleKeyDown(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;

    if (!validator.isEmail(value)) return alert("Invalid email");

    if (!value.trim()) return;
    setAdmins([...admins, { email: value }]);
    e.target.value = "";
  }

  return (
    <section
      className="my-3"
      style={{
        borderBottom: "0.2rem solid rgb(25, 118, 210)",
        borderRadius: "8px",
      }}
    >
      <div className="my-2 card text-center mx-5">
        <header className="h5 bannerfont mt-2" style={{ fontWeight: 600 }}>
          Admin list
        </header>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <div
          style={{
            backgroundColor: "#d3d3d3",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: "14px",
            width: "100%",
            borderRadius: "0.5rem",
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          {admins &&
            admins.map((item, i) => (
              <div
                key={i}
                style={{
                  backgroundColor: "#fff",
                  color: "#000",
                  padding: "0.5rem",
                  display: "inline-block",
                  borderRadius: "45px",
                }}
              >
                <span>{item.email}</span>
                <span
                  style={{
                    height: "1.25rem",
                    width: "1.25rem",
                    borderRadius: "50%",
                    textAlign: "center",
                    backgroundColor: "#444",
                    color: "#fff",
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    marginLeft: "0.5rem",
                  }}
                  onClick={() => removeAdmin(i)}
                >
                  &times;
                </span>
              </div>
            ))}
          <input
            type="text"
            name="meta-keywords"
            onKeyDown={handleKeyDown}
            placeholder={"Type admin email and press enter to add ..."}
            style={{
              flexGrow: 1,
              padding: "0.5rem",
              outline: "none",
              backgroundColor: "transparent",
              fontSize: "1.1rem",
              color: "black",
              fontFamily: "inherit",
            }}
          />
        </div>
      </div>

      <div className="attr-nav mt-lg-4 mt-md-2 pt-md-1">
        <Button
          variant="contained"
          onClick={() => {
            updateAdmin();
          }}
          disabled={loading}
          sx={{
            fontWeight: "700",
            "&:hover": {
              backgroundColor: "white",
              color: "#1976d2",
            },
            textTransform: "capitalize",
          }}
        >
          {loading ? "Updating..." : "Update Admins"}
        </Button>
      </div>
    </section>
  );
};

export default Playfab;
