import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useAnimation, motion } from "framer-motion";
import { useSelector } from "react-redux";

export const Feature = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const youtubevide_link = useSelector((state) => state.adminReducer);

  const squareVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.7 },
      zIndex: -10,
      width: '100%',
      height: '100%',
    },
    hidden: {
      opacity: 0,
      scale: 0,
      zIndex: -10,
      width: 0,
      height: 0,
      overflow: "hidden",
    },
  }

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  // useEffect(() => {
  //   //api call for video
  // }, []);

  return (
    <div>
      <div id="overview" className="software-overview-area p-2 my-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 ">
              <div className="site-heading text-center">
                <h5>Overview</h5>
                <h2 className="area-title bannerdec">Tutorial</h2>
                <div className="devider"></div>
              </div>
            </div>
          </div>
        </div>
        <motion.div
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={squareVariants}
          className="my--5 pr-5 pl-5 pt-0 text-center square mr-sm-0"
          style={{zIndex: -10}}
        >
          <div style={{ width: "100%", height: 500, zIndex: -10 }}>
            <iframe
              width="100%"
              height="100%"
              title="uniqueID"
              frameBorder="0"
              allow="encrypted-media"
              allowFullScreen
              className="shadow-lg"
              style={{zIndex: -10}}
              src={`https://www.youtube.com/embed/${youtubevide_link.homepage_youtube}?autoplay=0&rel=0`}
            ></iframe>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
