import React from "react";
import { useSelector } from "react-redux";

const Mail = () => {
  const list = useSelector((state) => state.adminReducer.mailingList);

  return (
    <section
      className="my-3"
      style={{
        borderBottom: "0.2rem solid rgb(25, 118, 210)",
        borderRadius: "8px",
      }}
    >
      <div className="my-2 card text-center mx-5">
        <header className="h5 bannerfont mt-2" style={{ fontWeight: 600 }}>
          Mail list
        </header>
        <div className="pt-1 pb-4">
          {list?.map((item, index) => {
            return (
              <div key={index} className="mailinglist">
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Mail;
